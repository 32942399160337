import './styles/main.scss';
import webgif from './assets/webdev-gif.json';

window.onload = function () {
  // console.log(webgif);

  lottie.loadAnimation({
    container: document.getElementById('lottie'), // the dom element that will contain the animation
    renderer: 'svg',
    loop: true,
    autoplay: true,
    // src: webgif,
    animationData: webgif, // Use animationData instead of src
  });

  // to avoild letting user pick dates before today
  const today = new Date().toISOString().split('T')[0];
  // Set the min attribute to today's date
  $('#completion-date').attr('min', today);

  if ($('#main-navbar')) {
    new bootstrap.ScrollSpy(document.body, {
      target: '#main-navbar',
      rootMargin: '0px 0px -40%',
    });
  }

  $.ajax({
    url: 'services.php',
    type: 'GET',
    success: function (data) {
      if (Array.isArray(data) && data.length > 0) {
        data.sort((a, b) => a.id - b.id);
        data.forEach(function (service) {
          // Create the checkbox HTML structure dynamically
          var checkboxHtml = `
          <div class="form-check">
            <input class="form-check-input" type="checkbox" id="service_${service.id}" name="services[]" value="${service.id}" />
            <label class="form-check-label" for="service_${service.id}">${service.label}</label>
          </div>`;

          $('#required-services').append(checkboxHtml);
        });
      }
    },
    error: function (xhr, status, error) {
      console.log(error);
    },
  });

  $('#contact-form').on('submit', function (event) {
    event.preventDefault(); // Prevent the form from submitting the normal way

    // Create FormData object
    var formData = new FormData(this);

    $.ajax({
      url: 'form-submit.php',
      type: 'POST',
      data: formData,

      processData: false, // Prevent jQuery from automatically transforming the data into a query string
      contentType: false, // Prevent jQuery from setting the content type header (so it works with FormData)
      success: function ({ status, message }) {
        if (status === 'success') {
          $('#notification-title').text('Success!');
          $('#notification-message').text(message);
          $('#notification').removeClass('d-none alert-danger').addClass('alert-success');
          $('#contact-form')[0].reset();
        } else {
          $('#notification-title').text('Error!');
          $('#notification-message').text(message);
          $('#notification').removeClass('d-none alert-success').addClass('alert-danger');
        }

        // Hide the notification after a few seconds
        setTimeout(function () {
          $('#notification').addClass('d-none');
        }, 3000); // 3 seconds
      },
      error: function (xhr, status, error) {
        // Set notification content for error
        $('#notification-title').text('Error!');
        $('#notification-message').text(
          'Could not submit your form. Please try again later.'
        );
        $('#notification').removeClass('d-none alert-success').addClass('alert-danger');

        setTimeout(function () {
          $('#notification').addClass('d-none');
        }, 3000);

        console.error('AJAX Error:', error);
      },
    });
  });
};
